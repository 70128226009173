import { PERMISSIONS } from "@/rbac/permissions.js";
import { ACCESS_LEVELS } from "@/rbac/accessLevels.js";

const VIEW_PERMISSIONS = [PERMISSIONS.INVVIEW];

const EDIT_PERMISSIONS = [PERMISSIONS.INVEDIT];

const EDIT_VERTICAL_CLR = [
  PERMISSIONS.ROLE_BMS_POWERUSER,
  PERMISSIONS.ROLE_BMS_SUPERUSER,
];

export const INVENTORY_FEATURES_PERMISSIONS = {
  [ACCESS_LEVELS.VIEW]: VIEW_PERMISSIONS,
  [ACCESS_LEVELS.EDIT]: EDIT_PERMISSIONS,
  editVerticalClearance: EDIT_VERTICAL_CLR,
};
