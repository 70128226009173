import { defineStore } from "pinia";
import { ref } from "vue";
import { USER_ENDPOINT } from "@/constants/Endpoints";
import { LOGGER } from "@/util/logger";
import axios from "axios";

export const useUserStore = defineStore("user", () => {
  let loggedInUser = ref(null);
  let isAuthenticated = ref(false);
  let userDistrict = ref(null);

  const setUser = (user) => {
    loggedInUser.value = user;
  };
  const setIsAuthenticated = (value) => {
    isAuthenticated.value = value;
  };
  const getUserDistrict = () => {
    return loggedInUser.value?.WorkUnitCode?.substring(0, 2);
  };

  const getUserRoles = () => {
    return loggedInUser.value?.groups?.split("^") || [];
  };

  const getUserSub = () => {
    return loggedInUser?.value?.sub;
  };

  const getHAUserName = () => {
    return loggedInUser.value?.HAUserID;
  };

  const addUser = async () => {
    let isSuccess = true;
    const url = USER_ENDPOINT.ADD_USER;
    await axios
      .post(url)
      .then((response) => {
        LOGGER.logEvent(
          LOGGER.EventAction.Add,
          LOGGER.EventCat.RequestResponse,
          "Add User",
          response
        );
      })
      .catch((e) => {
        LOGGER.logException(e);
        isSuccess = false;
      });
    return isSuccess;
  };

  return {
    addUser,
    userDistrict,
    loggedInUser,
    isAuthenticated,
    setUser,
    setIsAuthenticated,
    getUserDistrict,
    getUserRoles,
    getUserSub,
    getHAUserName,
  };
});
