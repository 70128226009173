import { createRouter, createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";

import { useInspectionStore } from "@/stores/inspection.js";
import { INSPECTION_PAGES } from "@/constants/Inspections";
import auth from "@/auth";
import { useConnectivityStore } from "@/stores/connectivity";
import { useUserStore } from "@/stores/userStore";
import { useAppViewStateStore } from "@/stores/appViewState";
import { useUserPermissionStore } from "@/stores/userPermission";
import {
  isUserGroupHaveAccess,
  ALL_CWOPA_PERMISSIONS,
  STRUCTURE_SEARCH_USER_PERMISSIONS,
  BP_ASSIGNMENT_USER_PERMISSIONS,
  BRIDGE_PROBLEM_REPORT_PERMISSIONS,
  ACCESS_LEVELS,
  STRUCTURE_IDENTIFICATION_PERMISSIONS,
  STRUCTURE_COMPOSITION_PERMISSIONS,
  BI_REPORTS_PERMISSIONS,
  SIGNS_AND_LIGHTS_PERMISSIONS,
  WALLS_PERMISSIONS,
  TUNNELS_PERMISSIONS,
  INVENTORY_FEATURES_PERMISSIONS,
  INSPECTION_PLANNING_PERMISSIONS,
  STRUCTURE_PLAN_PERMISSIONS,
  PRESERVATION_REHAB_PERMISSIONS,
} from "@/rbac";

/**
 * const LandingPage = () => import("@/views/LandingPage.vue");
 * const StructureInspection = () => import( "@/views/StructureInspection.vue");
 * const ViewWorkList = () => import( "@/views/ViewWorkList.vue");
 * const OfflineLogin = () => import("@/views/OfflineLogin.vue");
 *
 * We are not importing LandingPage, StructureInspection, ViewWorkList and OfflineLogin
 * dynamically because :
 * LandingPage:: when user login to the app user is navigated to the landing page.
 * So, it dosen't make sence to load Landing page dynamically.
 *
 * StructureInspection, ViewWorkList and OfflineLogin:: is required in the main bundle as
 * we need them for application to work offline.
 *
 * Rest all other routes can be loaded dynamically. Which
 * helps us to split code and save some KBs of data getting downloaded.
 */
import LandingPage from "@/views/LandingPage.vue";
import StructureInspection from "@/views/StructureInspection.vue";
import OfflineLogin from "@/views/OfflineLogin.vue";
import ViewWorkList from "@/views/ViewWorkList.vue";
import ViewServiceStatus from "@/views/ViewServiceStatus.vue";
const StructureSearch = () => import("@/views/StructureSearch.vue");
const LogoutPage = () => import("@/views/LogoutPage.vue");
const OtherLinks = () => import("@/views/OtherLinks.vue");
const InventoryLinks = () => import("@/views/InventoryLinks.vue");
const CallbackPage = () => import("@/views/CallbackPage.vue");
const StructurePlan = () => import("@/views/StructurePlan.vue");
const InspectionLinks = () => import("@/views/InspectionLinks.vue");

const EXTERNAL_ROUTES = ["external", "external/index.html"];
const routes = [
  {
    path: "/",
    redirect: (to) => {
      if (to.query.error == "access_denied") {
        window.location.href = import.meta.env.VITE_APP_ESEC_ACCESS_DENIED_URI;
        return "/redirecting";
      }
      return "/callback";
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/landingPage",
    component: LandingPage,
    name: "LandingPage",
    meta: { requiresAuth: true, availableOffline: true },
  },
  {
    path: "/callback",
    name: "Callback",
    component: CallbackPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/inspections",
    name: "Inspections",
    component: StructureSearch,
    meta: { requiresAuth: true, availableOffline: true },
    children: [],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "LandingPage",
    component: LandingPage,
    props: { external: false },
  },
  {
    path: "/structurePlan/Search",
    name: "StructurePlanSearch",
    component: StructurePlan,
    meta: {
      requiresAuth: true,
      availableOffline: false,
      userRoleRestricted: true,
      permissions: STRUCTURE_PLAN_PERMISSIONS,
    },
  },
  {
    path: "/structurePlan/Details",
    name: "StructurePlanDetails",
    component: StructurePlan,
    meta: {
      requiresAuth: true,
      availableOffline: false,
      userRoleRestricted: true,
      permissions: STRUCTURE_PLAN_PERMISSIONS,
    },
  },
  {
    path: "/structurePlan/Details/:structureReqId",
    name: "StructurePlanDetailsWithStructureReqId",
    component: StructurePlan,
    meta: {
      requiresAuth: true,
      availableOffline: false,
      userRoleRestricted: true,
      permissions: STRUCTURE_PLAN_PERMISSIONS,
    },
  },
  {
    path: "/inspections/structure-search",
    name: "InspectionsStructureSearch",
    component: StructureSearch,
    meta: { requiresAuth: true, availableOffline: false },
  },
  {
    path: "/inspections/structures/:brkey",
    name: "LatestInspection",
    component: StructureInspection,
    meta: { requiresAuth: true, availableOffline: true },
  },
  {
    path: "/inspections/structures/:brkey?page=:pageName",
    redirect: (to) => {
      return {
        ...to,
        name: "LatestInspection",
        params: { brkey: to.params.brkey },
        query: { page: to.query.pageName },
      };
    },
    meta: { requiresAuth: true, availableOffline: true },
  },
  {
    path: "/inspections/structures/:brkey/inspections/:inspkey",
    name: "SpecificInspection",
    component: StructureInspection,
    meta: { requiresAuth: true, availableOffline: true },
  },
  {
    path: "/inspections/structures/:brkey/inspections/:inspkey?page=:pageName",
    redirect: (to) => {
      return {
        ...to,
        name: "SpecificInspection",
        params: { brkey: to.params.brkey, inspkey: to.params.inspkey },
        query: { page: to.query.pageName },
      };
    },
    meta: { requiresAuth: true, availableOffline: true },
  },
  {
    path: "/otherLinks/bulletinBoard",
    name: "BulletinBoard",
    component: OtherLinks,
    meta: {
      availableOffline: false,
    },
  },
  {
    path: "/otherLinks/linkMaintenance",
    name: "LinkMaintenance",
    component: OtherLinks,
    meta: {
      requiresAuth: true,
      availableOffline: false,
      userRoleRestricted: true,
      permissions: ALL_CWOPA_PERMISSIONS,
    },
  },
  {
    path: "/otherLinks/structureSearch?bmsId=:bmsId",
    redirect: (to) => {
      return {
        ...to,
        name: "StructureSearch",
        query: { bmsId: to.query.bmsId },
        meta: {
          requiresAuth: true,
          availableOffline: false,
          userRoleRestricted: true,
          permissions: STRUCTURE_SEARCH_USER_PERMISSIONS,
        },
      };
    },
    meta: {
      requiresAuth: true,
      availableOffline: false,
      userRoleRestricted: true,
      permissions: STRUCTURE_SEARCH_USER_PERMISSIONS,
    },
  },
  {
    path: "/otherLinks/bpAssignment",
    name: "BpAssignment",
    component: OtherLinks,
    meta: {
      requiresAuth: true,
      availableOffline: false,
      userRoleRestricted: true,
      permissions: BP_ASSIGNMENT_USER_PERMISSIONS,
    },
  },
  {
    path: "/otherLinks/inspectionReportGenerator",
    name: "InspectionReportGenerator",
    component: OtherLinks,
    meta: {
      requiresAuth: true,
      availableOffline: false,
      userRoleRestricted: true,
      permissions: BP_ASSIGNMENT_USER_PERMISSIONS,
    },
  },
  {
    path: "/otherLinks/structureSearch",
    name: "StructureSearch",
    component: OtherLinks,
    meta: {
      requiresAuth: true,
      availableOffline: false,
      userRoleRestricted: true,
      permissions: STRUCTURE_SEARCH_USER_PERMISSIONS,
    },
  },
  {
    path: "/otherLinks/bridgeProblemReport",
    name: "BridgeProblemReport",
    component: OtherLinks,
    meta: {
      requiresAuth: true,
      availableOffline: false,
      userRoleRestricted: true,
      permissions: BRIDGE_PROBLEM_REPORT_PERMISSIONS,
    },
  },
  {
    path: "/otherLinks/reports",
    name: "Reports",
    component: OtherLinks,
    meta: {
      requiresAuth: true,
      availableOffline: false,
      userRoleRestricted: true,
      permissions: BI_REPORTS_PERMISSIONS,
    },
  },
  {
    path: "/otherLinks/riskScoreSearch",
    name: "RiskScoreSearch",
    component: OtherLinks,
    meta: {
      requiresAuth: true,
      availableOffline: false,
      userRoleRestricted: true,
      permissions: ALL_CWOPA_PERMISSIONS,
    },
  },
  {
    path: "/inventoryLinks/structureComposition",
    component: InventoryLinks,
    name: "StructureComposition",
    meta: {
      requiresAuth: true,
      availableOffline: false,
      userRoleRestricted: true,
      permissions: STRUCTURE_COMPOSITION_PERMISSIONS,
    },
  },
  {
    path: "/inventoryLinks/structureIdentification",
    component: InventoryLinks,
    name: "StructureIdentification",
    meta: {
      requiresAuth: true,
      availableOffline: false,
      userRoleRestricted: true,
      permissions: STRUCTURE_IDENTIFICATION_PERMISSIONS,
    },
  },
  {
    path: "/inventoryLinks/features",
    component: InventoryLinks,
    name: "InventoryFeatures",
    meta: {
      requiresAuth: true,
      availableOffline: false,
      userRoleRestricted: true,
      permissions: INVENTORY_FEATURES_PERMISSIONS,
    },
  },
  {
    path: "/inventoryLinks/inspectionPlanning",
    component: InventoryLinks,
    name: "InspectionPlanning",
    meta: {
      requiresAuth: true,
      availableOffline: false,
      userRoleRestricted: true,
      permissions: INSPECTION_PLANNING_PERMISSIONS,
    },
  },
  {
    path: "/inventoryLinks/preservationRehab",
    component: InventoryLinks,
    name: "PreservationRehab",
    meta: {
      requiresAuth: true,
      availableOffline: false,
      userRoleRestricted: true,
      permissions: PRESERVATION_REHAB_PERMISSIONS,
    },
  },
  {
    path: "/inventoryLinks/riskScoreDetails",
    component: InventoryLinks,
    name: "RiskScoreDetails",
    meta: { requiresAuth: true, availableOffline: false },
  },
  {
    path: "/inventoryLinks/signAndLights",
    component: InventoryLinks,
    name: "SignAndLights",
    meta: {
      requiresAuth: true,
      availableOffline: false,
      userRoleRestricted: true,
      permissions: SIGNS_AND_LIGHTS_PERMISSIONS,
    },
  },
  {
    path: "/inventoryLinks/walls",
    component: InventoryLinks,
    name: "Walls",
    meta: {
      requiresAuth: true,
      availableOffline: false,
      userRoleRestricted: true,
      permissions: WALLS_PERMISSIONS,
    },
  },
  {
    path: "/inventoryLinks/tunnels",
    component: InventoryLinks,
    name: "Tunnels",
    meta: {
      requiresAuth: true,
      availableOffline: false,
      userRoleRestricted: true,
      permissions: TUNNELS_PERMISSIONS,
    },
  },
  {
    path: "/inspections/work-list",
    name: "ViewWorkList",
    component: ViewWorkList,
    meta: { requiresAuth: true, availableOffline: true },
  },
  {
    path: "/support/serviceStatus",
    name: "ServiceStatus",
    component: ViewServiceStatus,
    meta: { requiresAuth: true, availableOffline: false },
  },
  {
    path: "/offlineLogin",
    name: "OfflineLogin",
    component: OfflineLogin,
    meta: { requiresAuth: true, availableOffline: true },
  },
  // Redirect not found path to Landing Page
  {
    path: "/:pathMatch(.*)*",
    meta: { requiresAuth: true },
    beforeEnter: (to, _, next) => {
      console.log("pathmatch before enter", to);
      if (EXTERNAL_ROUTES.includes(to.params.pathMatch?.join("/"))) {
        next(false);
      }
      next({ path: "/landingPage" });
    },
  },
  {
    path: "/logout",
    name: "LogoutPage",
    component: LogoutPage,
    meta: { requiresAuth: false, availableOffline: true },
  },
  {
    path: "/inspectionLinks/deck",
    component: InspectionLinks,
    name: "Deck",
    meta: {
      requiresAuth: true,
      availableOffline: false,
      userRoleRestricted: true,
      permissions: ALL_CWOPA_PERMISSIONS,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const checkOnlineAuthentication = async (next, to) => {
  if (!to.name == "LogoutPage") {
    const user = await auth.getUser();
    if (user) {
      if (!user.expired) {
        next();
      } else {
        await auth.login();
      }
    } else {
      await auth.login();
    }
  } else {
    next();
  }
};

const redirectToLogin = async (next, to) => {
  const connectivity = useConnectivityStore();
  const userStore = useUserStore();
  await connectivity.getServiceStatus();
  if (!connectivity.getisOnline) {
    //offline login
    if (userStore.isAuthenticated) {
      next();
    } else {
      next({ name: "OfflineLogin" });
    }
  } else {
    await checkOnlineAuthentication(next, to);
  }
};

const isUserAllowedToAccessRoute = (to) => {
  const userStore = useUserStore();
  const userPermissionStore = useUserPermissionStore();
  const meta = to?.redirectedFrom?.meta?.userRoleRestricted
    ? to.redirectedFrom.meta
    : to.meta;
  if (meta.userRoleRestricted) {
    /* condition (`meta.permissions ? `) will be removed in future */
    return meta.permissions
      ? userPermissionStore.checkUserPermission(
          meta.permissions[ACCESS_LEVELS.VIEW],
          userPermissionStore.appPermissions
        )
      : isUserGroupHaveAccess(
          userStore.getUserRoles(),
          meta.rolesAllowed[ACCESS_LEVELS.VIEW]
        );
  }
  return true;
};

const checkUnsavedChanges = (to, from) => {
  const inspectionStore = useInspectionStore();
  if (from.name === "LatestInspection" || from.name === "SpecificInspection") {
    if (inspectionStore.getDirtyFlag) {
      inspectionStore.setNextRoute(to.path);
      inspectionStore.setNextPage(INSPECTION_PAGES.GENERAL);
      inspectionStore.setUnsavedChangesWarning(true);
      return false;
    }
  }
  return true;
};

router.afterEach((to, from, failure) => {
  const { setIsRouteChanged } = useAppViewStateStore();
  setIsRouteChanged(false);
  if (failure) {
    console.log(to, from, failure);
  }
});

router.beforeEach(async (to, from, next) => {
  const { setIsRouteChanged } = useAppViewStateStore();
  if (isUserAllowedToAccessRoute(to)) {
    setIsRouteChanged(true);
    if (to.name == "OfflineLogin") {
      const connectivity = useConnectivityStore();
      await connectivity.getServiceStatus();
      if (!connectivity.getOnlineServiceStatus) {
        next();
      } else {
        next({ name: "LandingPage" });
      }
    } else if (to.meta.requiresAuth) {
      await redirectToLogin(next, to);
    } else {
      next();
    }
  } else {
    next(false);
  }
  return checkUnsavedChanges(to, from);
});

trackRouter(router);

export default router;
