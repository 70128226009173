export const PERMISSIONS = {
  ANONYMOUS: "ANONYMOUS",
  APRASVIEW: "APRASVIEW",
  BBEDIT: "BBEDIT",
  BBVIEW: "BBVIEW",
  BOEVIEW: "BOEVIEW",
  BPMULTIASSIGN: "BPMULTIASSIGN",
  BRIDGEADMINADD: "BRIDGEADMINADD",
  BRIDGEADMINEDIT: "BRIDGEADMINEDIT",
  BRIDGEADMINVIEW: "BRIDGEADMINVIEW",
  CREATESTRUCTURE: "CREATESTRUCTURE",
  DATACONFIG: "DATACONFIG",
  EDMSEDIT: "EDMSEDIT",
  EDMSVIEW: "EDMSVIEW",
  INSPAPPREDIT: "INSPAPPREDIT",
  INSPAPPRVIEW: "INSPAPPRVIEW",
  INSPDOWNLOAD: "INSPDOWNLOAD",
  INSPEDIT: "INSPEDIT",
  INSPSUBMIT: "INSPSUBMIT",
  INSPVIEW: "INSPVIEW",
  INSP_DEL: "INSP_DEL",
  INVEDIT: "INVEDIT",
  INVVIEW: "INVVIEW",
  POSTINGEDIT: "POSTINGEDIT",
  POSTINGVIEW: "POSTINGVIEW",
  STRUCTURESEARCH: "STRUCTURESEARCH",
  SPNEDIT: "SPNEDIT",
  SPNVIEW: "SPNVIEW",
  USERPREFEDIT: "USERPREFEDIT",

  ROLE_BMS: "ROLE_BMS",
  ROLE_BMS_APPLICATION_SECURITY_ADMINISTRATION:
    "ROLE_BMS_APPLICATION_SECURITY_ADMINISTRATION",
  ROLE_BMS_APRAS_BROWSER: "ROLE_BMS_APRAS_BROWSER",
  ROLE_BMS_APRAS_EDIT: "ROLE_BMS_APRAS_EDIT",
  ROLE_BMS_BROWSER: "ROLE_BMS_BROWSER",
  ROLE_BMS_BROWSER_INSPECTION: "ROLE_BMS_BROWSER_INSPECTION",
  ROLE_BMS_BROWSER_INVENTORY: "ROLE_BMS_BROWSER_INVENTORY",
  ROLE_BMS_LBI_USER: "ROLE_BMS_LBI_USER",
  ROLE_BMS_POWERUSER: "ROLE_BMS_POWERUSER",
  ROLE_BMS_REGISTRAR: "ROLE_BMS_REGISTRAR",
  ROLE_BMS_SUPERUSER: "ROLE_BMS_SUPERUSER",
  ROLE_SPN: "ROLE_SPN",
  ROLE_SPN_APPLICATION_SECURITY_ADMINISTRATOR:
    "ROLE_SPN_APPLICATION_SECURITY_ADMINISTRATOR",
  ROLE_SPN_APPROVE_DIST: "ROLE_SPN_APPROVE_DIST",
  ROLE_SPN_APPROVE_STATE: "ROLE_SPN_APPROVE_STATE",
  ROLE_SPN_EDIT: "ROLE_SPN_EDIT",
  ROLE_SPN_VIEW: "ROLE_SPN_VIEW",
  ROLE_BMS_INSPECTOR: "ROLE_BMS_INSPECTOR",
  ROLE_BMS_INSPECTOR_SUPERVISOR: "ROLE_BMS_INSPECTOR_SUPERVISOR",
};
