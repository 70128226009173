import { INSPECTION_PAGES } from "@/constants/Inspections";
import { useInspectionStore } from "@/stores/inspection";
import { setCulvertDocDefinition } from "@/reports/CulvertReport.js";
import { setSuperstructureDocDefinition } from "@/reports/SuperstructureReport.js";
import { setWallsDocDefinition } from "@/reports/WallsReport.js";
import { setSignsLightsDocDefinition } from "@/reports/SignsLightsReport.js";
import { setTunnelsDocDefinition } from "@/reports/TunnelsReport.js";
import { setElementsDocDefinition } from "@/reports/ElementsReport.js";
import { setNotesDocDefinition } from "@/reports/NotesReport.js";
import { setFeaturesDocDefinition } from "@/reports/FeaturesReport.js";
import { setJointsDocDefinition } from "@/reports/JointsReport.js";
import { setWaterwayDocDefinition } from "@/reports/WaterwayReport.js";
import { setSubstructureDocDefinition } from "@/reports/SubstructureReport.js";
import { setGeneralDocDefinition } from "@/reports/GeneralReport.js";
import { setBearingsDocDefinition } from "@/reports/BearingsReport.js";
import { setDeckDocDefinition } from "@/reports/DeckReport.js";
import { setNSTMFatigueDocDefinition } from "@/reports/NSTMFatigueReport.js";
import { setScheduleDocDefinition } from "@/reports/ScheduleReport.js";
import { setApproachDocDefinition } from "@/reports/ApproachReport.js";

import {
  setLoadRatingsTunnelDocDefinition,
  setLoadRatingsDocDefinition,
} from "@/reports/LoadRatingsReport.js";
import { setMaintenanceDocDefinition } from "@/reports/MaintenanceReport.js";
import { setRatingsDocDefinition } from "@/reports/RatingsReport.js";
import pdfMake from "pdfmake/build/pdfmake";
import { vfs } from "@/assets/vfs_fonts.js";
import { styles } from "@/reports/reportStyles";
import { getReportFooter } from "@/reports/ReportUtil";
import { getReportHeader } from "./ReportUtil";
import useStructureType from "@/composables/structureType";

async function generateReport(pages, justCreate) {
  const inspectionStore = useInspectionStore();
  let docDefinition = {};
  const { getStructureType } = useStructureType();

  if (pages.length == 1) {
    switch (pages[0]) {
      case INSPECTION_PAGES.CULVERT:
        docDefinition = setCulvertDocDefinition(
          inspectionStore.selectedInspection
        );
        break;
      case INSPECTION_PAGES.SUPERSTRUCTURE:
        docDefinition = setSuperstructureDocDefinition(
          inspectionStore.selectedInspection
        );
        break;
      case INSPECTION_PAGES.SUBSTRUCTURE:
        docDefinition = setSubstructureDocDefinition(
          inspectionStore.selectedInspection
        );
        break;
      case INSPECTION_PAGES.WALLS:
        docDefinition = setWallsDocDefinition(
          inspectionStore.selectedInspection
        );
        break;
      case INSPECTION_PAGES.RATINGS:
        docDefinition = setRatingsDocDefinition(
          inspectionStore.selectedInspection
        );
        break;
      case INSPECTION_PAGES.SIGNS_AND_LIGHTS:
        docDefinition = setSignsLightsDocDefinition(
          inspectionStore.selectedInspection
        );
        break;
      case INSPECTION_PAGES.TUNNELS:
        docDefinition = setTunnelsDocDefinition(
          inspectionStore.selectedInspection
        );
        break;
      case INSPECTION_PAGES.LOAD_RATING:
        docDefinition = loadRatingsPage(
          getStructureType,
          inspectionStore,
          docDefinition
        );
        break;
      case INSPECTION_PAGES.ELEMENTS:
        docDefinition = setElementsDocDefinition(
          inspectionStore.selectedInspection
        );
        break;
      case INSPECTION_PAGES.WATERWAY:
        docDefinition = setWaterwayDocDefinition(
          inspectionStore.selectedInspection
        );
        break;
      case INSPECTION_PAGES.NOTES:
        docDefinition = setNotesDocDefinition(
          inspectionStore.selectedInspection
        );
        break;
      case INSPECTION_PAGES.GENERAL:
        docDefinition = setGeneralDocDefinition(
          inspectionStore.selectedInspection
        );
        break;
      case INSPECTION_PAGES.FEATURES:
        docDefinition = setFeaturesDocDefinition(
          inspectionStore.selectedInspection
        );
        break;
      case INSPECTION_PAGES.MAINTENANCE_ITEMS:
        docDefinition = maintenancePage(pages, inspectionStore, docDefinition);
        break;
      case INSPECTION_PAGES.JOINTS:
        docDefinition = setJointsDocDefinition(
          inspectionStore.selectedInspection
        );
        break;
      case INSPECTION_PAGES.BEARINGS:
        docDefinition = setBearingsDocDefinition(
          inspectionStore.selectedInspection
        );
        break;
      case INSPECTION_PAGES.DECK:
        docDefinition = setDeckDocDefinition(
          inspectionStore.selectedInspection
        );
        break;
      case INSPECTION_PAGES.NSTM_FATIGUE:
        docDefinition = setNSTMFatigueDocDefinition(
          inspectionStore.selectedInspection
        );
        break;
      case INSPECTION_PAGES.SCHEDULE:
        docDefinition = setScheduleDocDefinition(
          inspectionStore.selectedInspection
        );
        break;
      case INSPECTION_PAGES.APPROACH:
        docDefinition = setApproachDocDefinition(
          inspectionStore.selectedInspection
        );
        break;
      default:
        break;
    }
  } else if (pages.length > 1) {
    const content = combineMultipleContents(pages);
    docDefinition = setupMultiplePages(content, inspectionStore);
  }

  pdfMake.vfs = vfs;

  pdfMake.fonts = {
    Roboto: {
      normal: "Roboto-Regular.ttf",
      bold: "Roboto-Medium.ttf",
      italics: "Roboto-Italic.ttf",
      bolditalics: "Roboto-MediumItalic.ttf",
    },
    FontAwesomeSolid: {
      normal: "fa-solid-900.ttf",
      bold: "fa-solid-900.ttf",
      italics: "fa-solid-900.ttf",
      bolditalics: "fa-solid-900.ttf",
    },
    CustomFontAwesome: {
      normal: "fontawesome-regular-checkboxes.ttf",
      bold: "fontawesome-regular-checkboxes.ttf",
      italics: "fontawesome-regular-checkboxes.ttf",
      bolditalics: "fontawesome-regular-checkboxes.ttf",
    },
  };
  let fileName = `BMS3_${inspectionStore.getBrKey}.pdf`;
  await finishCreation(justCreate, docDefinition, fileName, inspectionStore);
}
function combineMultipleContents(pages) {
  const inspectionStore = useInspectionStore();
  let content = [];
  const { getStructureType } = useStructureType();
  pages.forEach((p) => {
    const pageIndex = pages.indexOf(p);
    const totalPages = pages.length;
    switch (p) {
      case INSPECTION_PAGES.CULVERT:
        content.push(
          setCulvertDocDefinition(inspectionStore.selectedInspection).content
        );
        addPageBreak(content, pageIndex, totalPages);
        break;
      case INSPECTION_PAGES.SUPERSTRUCTURE:
        content.push(
          setSuperstructureDocDefinition(inspectionStore.selectedInspection)
            .content
        );
        addPageBreak(content, pageIndex, totalPages);
        break;
      case INSPECTION_PAGES.SUBSTRUCTURE:
        content.push(
          setSubstructureDocDefinition(inspectionStore.selectedInspection)
            .content
        );
        addPageBreak(content, pageIndex, totalPages);
        break;
      case INSPECTION_PAGES.WALLS:
        content.push(
          setWallsDocDefinition(inspectionStore.selectedInspection).content
        );
        addPageBreak(content, pageIndex, totalPages);
        break;
      case INSPECTION_PAGES.SIGNS_AND_LIGHTS:
        content.push(
          setSignsLightsDocDefinition(inspectionStore.selectedInspection)
            .content
        );
        addPageBreak(content, pageIndex, totalPages);
        break;
      case INSPECTION_PAGES.RATINGS:
        content.push(
          setRatingsDocDefinition(inspectionStore.selectedInspection).content
        );
        addPageBreak(content, pageIndex, totalPages);
        break;
      case INSPECTION_PAGES.TUNNELS:
        content.push(
          setTunnelsDocDefinition(inspectionStore.selectedInspection).content
        );
        addPageBreak(content, pageIndex, totalPages);
        break;
      case INSPECTION_PAGES.LOAD_RATING:
        if (
          getStructureType(
            inspectionStore.selectedInspection.Bridge?.SERVTYPON
          ) === "T"
        ) {
          content.push(
            setLoadRatingsTunnelDocDefinition(
              inspectionStore.selectedInspection
            ).content
          );
        } else {
          content.push(
            setLoadRatingsDocDefinition(inspectionStore.selectedInspection)
              .content
          );
        }
        addPageBreak(content, pageIndex, totalPages);
        break;
      case INSPECTION_PAGES.ELEMENTS:
        content.push(
          setElementsDocDefinition(inspectionStore.selectedInspection).content
        );
        addPageBreak(content, pageIndex, totalPages);
        break;
      case INSPECTION_PAGES.WATERWAY:
        content.push(
          setWaterwayDocDefinition(inspectionStore.selectedInspection).content
        );
        addPageBreak(content, pageIndex, totalPages);
        break;
      case INSPECTION_PAGES.NOTES:
        content.push(
          setNotesDocDefinition(inspectionStore.selectedInspection).content
        );
        addPageBreak(content, pageIndex, totalPages);
        break;
      case INSPECTION_PAGES.GENERAL:
        content.push(
          setGeneralDocDefinition(inspectionStore.selectedInspection).content
        );
        addPageBreak(content, pageIndex, totalPages);
        break;
      case INSPECTION_PAGES.FEATURES:
        content.push(
          setFeaturesDocDefinition(inspectionStore.selectedInspection).content
        );
        addPageBreak(content, pageIndex, totalPages);
        break;
      case INSPECTION_PAGES.BEARINGS:
        content.push(
          setBearingsDocDefinition(inspectionStore.selectedInspection).content
        );
        addPageBreak(content, pageIndex, totalPages);
        break;
      case INSPECTION_PAGES.JOINTS:
        content.push(
          setJointsDocDefinition(inspectionStore.selectedInspection).content
        );
        addPageBreak(content, pageIndex, totalPages);
        break;
      case INSPECTION_PAGES.DECK:
        content.push(
          setDeckDocDefinition(inspectionStore.selectedInspection).content
        );
        addPageBreak(content, pageIndex, totalPages);
        break;
      case INSPECTION_PAGES.MAINTENANCE_ITEMS:
        if (pages.includes(INSPECTION_PAGES.MAINTENANCE_INCLUDE)) {
          content.push(
            setMaintenanceDocDefinition(
              inspectionStore.selectedInspection,
              true
            ).content
          );
        } else {
          content.push(
            setMaintenanceDocDefinition(
              inspectionStore.selectedInspection,
              false
            ).content
          );
        }
        addPageBreak(content, pageIndex, totalPages);
        break;
      case INSPECTION_PAGES.NSTM_FATIGUE:
        content.push(
          setNSTMFatigueDocDefinition(inspectionStore.selectedInspection)
            .content
        );
        addPageBreak(content, pageIndex, totalPages);
        break;
      case INSPECTION_PAGES.SCHEDULE:
        content.push(
          setScheduleDocDefinition(inspectionStore.selectedInspection).content
        );
        addPageBreak(content, pageIndex, totalPages);
        break;
      case INSPECTION_PAGES.APPROACH:
        content.push(
          setApproachDocDefinition(inspectionStore.selectedInspection).content
        );
        addPageBreak(content, pageIndex, totalPages);
        break;
      // Add pages above ^^^
      default:
        break;
    }
  });
  return content;
}

function addPageBreak(content, pageIndex, totalPages) {
  if (pageIndex != totalPages - 1) {
    content.push([{ text: "", pageBreak: "after" }]);
  }
}

async function finishCreation(
  justCreate,
  docDefinition,
  fileName,
  inspectionStore
) {
  if (justCreate) {
    pdfMake.createPdf(docDefinition).download(fileName);
  } else {
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.download(fileName);
    await new Promise((resolve) => {
      pdfDocGenerator.getBlob((blob) => {
        const file = new File([blob], fileName, {
          type: "application/pdf",
        });
        inspectionStore.setReportToSubmit(file);
        resolve();
      });
    });
  }
}

function setupMultiplePages(content, inspectionStore) {
  return {
    header: function (currentPage) {
      // set page header based on the pageHeaderText value for each page
      let reportTitle = "";
      for (const value of content) {
        if (
          value?.stack[0]?.pageHeaderText &&
          currentPage >= value?.positions[0]?.pageNumber
        ) {
          reportTitle = value?.stack[0]?.pageHeaderText;
        }
      }
      return getReportHeader(reportTitle, inspectionStore.selectedInspection);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: content,
    styles: styles,
    defaultStyle: {
      fontSize: 10,
    },
  };
}

function loadRatingsPage(getStructureType, inspectionStore, docDefinition) {
  if (
    getStructureType(inspectionStore.selectedInspection.Bridge?.SERVTYPON) ===
    "T"
  ) {
    docDefinition = setLoadRatingsTunnelDocDefinition(
      inspectionStore.selectedInspection
    );
  } else {
    docDefinition = setLoadRatingsDocDefinition(
      inspectionStore.selectedInspection
    );
  }
  return docDefinition;
}

function maintenancePage(pages, inspectionStore, docDefinition) {
  if (pages.includes(INSPECTION_PAGES.MAINTENANCE_INCLUDE)) {
    docDefinition = setMaintenanceDocDefinition(
      inspectionStore.selectedInspection,
      true
    );
  } else {
    docDefinition = setMaintenanceDocDefinition(
      inspectionStore.selectedInspection,
      false
    );
  }
  return docDefinition;
}

export { generateReport };
