import { styles } from "@/reports/reportStyles";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import useDeckData from "@/composables/deckData";
import useDateField from "@/composables/dateField.js";

function setDeckDocDefinition(structure) {
  const configStore = useConfigStore();
  const { getData } = useDeckData();
  const [roadwayWidth] = getData.value;
  const { getFormattedDateStringNoTime } = useDateField();
  let docDefinition = {
    header: function () {
      return getReportHeader("Deck Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "Deck Page" },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Deck Conditions",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            width: "40%",
            text: "1A01 Deck Condition Rating: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.InspEvnt?.DKRATING
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        text: "Overall Deck Notes: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.OVERALL_DECK_COMMENTS
        )?.NOTES,
      },
      {
        columns: [
          {
            width: "40%",
            text: "6B40 Wearing Surface Condition Rating: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.UserInsp?.DECK_WS_COND_RATE
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        text: "Overall Wearing Surface Notes: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE ==
            INSPECTION_COMMENTS.OVERALL_WEARING_SURFACE_COMMENTS
        )?.NOTES,
      },
      {
        columns: [
          {
            width: "50%",
            text: "5B02 Surface Type Main: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.DECK_SURFACE_TYPE,
              structure?.Bridge?.DKSURFTYPE
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "50%",
            text: "5B03 Membrane Type Main: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.DECK_MEMBRANE_TYPE,
              structure?.Bridge?.DKMEMBTYPE
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "50%",
            text: "5B04 Protection Main: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.DECK_PROTECTION_TYPE,
              structure?.Bridge?.DKPROTECT
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "50%",
            text: "6A33 Wearing Surface Thickness Main/Approach: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.MILLIMETERS_TO_INCHES,
                structure?.UserBrdg?.MAIN_WS_THICKNESS,
                ROUNDING_FACTOR.TENTH
              ) + " in",
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.MILLIMETERS_TO_INCHES,
                structure?.UserBrdg?.APPR_WS_THICKNESS,
                ROUNDING_FACTOR.TENTH
              ) + " in",
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "50%",
            text: "6A34 Wearing Surface Date Recorded Main/Approach: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: getFormattedDateStringNoTime(
              structure?.UserBrdg?.MAIN_WS_THICK_DATE
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: getFormattedDateStringNoTime(
              structure?.UserBrdg?.APPR_WS_THICK_DATE
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "50%",
            text: "6A36 Protect Install Year: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: structure.UserBrdg.PROTECT_YEAR,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "50%",
            text: "6A37 Protect Install Note: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: structure.UserBrdg.PROTECT_NOTE,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "50%",
            text: "6B47 Deck Cracking Metric: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: structure?.UserInsp?.DECK_CRACKING_METRIC
              ? structure?.UserInsp?.DECK_CRACKING_METRIC + " YD/SY"
              : " YD/SY",
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "50%",
            text: "B.G.13 Maximum Bridge Height: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.METERS_TO_FEET,
                structure?.UserBrdg?.MAX_STRUCTURE_HEIGHT,
                ROUNDING_FACTOR.ROUND
              ) + " ft",
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "50%",
            text: "5B01 Deck Structure Type: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.DECK_STRUCTURE_TYPE,
              structure?.Bridge?.DKSTRUCTYP
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "50%",
            text: "5B07 Deck Width (O/O): ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.METERS_TO_FEET,
                structure?.Bridge?.DECKWIDTH,
                ROUNDING_FACTOR.TENTH
              ) + " ft",
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "50%",
            text: "5C27 Roadway Width (C/C): ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.METERS_TO_FEET,
                roadwayWidth,
                ROUNDING_FACTOR.TENTH
              ) + " ft",
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "50%",
            text: "5B05 Left Curb or Sidewalk Width: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.METERS_TO_FEET,
                structure?.Bridge?.LFTCURBSW,
                ROUNDING_FACTOR.TENTH
              ) + " ft",
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "50%",
            text: "5B06 Right Curb or Sidewalk Width: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.METERS_TO_FEET,
                structure?.Bridge?.RTCURBSW,
                ROUNDING_FACTOR.TENTH
              ) + " ft",
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "50%",
            text: "5B08 Bridge Median Type: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.BRIDGE_MEDIAN,
              structure?.Bridge?.BRIDGEMED,
              true
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Deck Inspection Notes",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        stack: [
          {
            text: "Deck Top and Wearing Surface: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: structure?.T_Insp_Comment?.find(
              (element) =>
                element?.COMMENT_TYPE ==
                INSPECTION_COMMENTS.DECK_TOP_WEARING_SURFACE
            )?.NOTES,
          },
        ],
        unbreakable: true,
      },
      {
        stack: [
          {
            text: "Deck Underside: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: structure?.T_Insp_Comment?.find(
              (element) =>
                element?.COMMENT_TYPE == INSPECTION_COMMENTS.DECK_UNDERSIDE
            )?.NOTES,
          },
        ],
        unbreakable: true,
      },
      {
        stack: [
          {
            text: "Deck Drainage: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: structure?.T_Insp_Comment?.find(
              (element) =>
                element?.COMMENT_TYPE == INSPECTION_COMMENTS.DECK_DRAINAGE
            )?.NOTES,
          },
        ],
        unbreakable: true,
      },
    ],
    styles: styles,
    defaultStyle: {
      fontSize: 10,
    },
  };
  return docDefinition;
}

export { setDeckDocDefinition };
