import { PERMISSIONS } from "@/rbac/permissions.js";
import { ACCESS_LEVELS } from "@/rbac/accessLevels.js";

const VIEW_PERMISSIONS = [PERMISSIONS.INVVIEW];

const EDIT_PERMISSIONS = [PERMISSIONS.INVEDIT];

export const PRESERVATION_REHAB_PERMISSIONS = {
  [ACCESS_LEVELS.VIEW]: VIEW_PERMISSIONS,
  [ACCESS_LEVELS.EDIT]: EDIT_PERMISSIONS,
};
