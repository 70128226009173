import { PERMISSIONS } from "@/rbac/permissions.js";
import { ACCESS_LEVELS } from "@/rbac/accessLevels.js";
import { ALL_CWOPA_PERMISSIONS } from "@/rbac/cwopaPermissions";

const cwopaPermissions = ALL_CWOPA_PERMISSIONS[ACCESS_LEVELS.VIEW];
const VIEW_PERMISSIONS = [...cwopaPermissions];
const ADD_PERMISSIONS = [...cwopaPermissions];
const EDIT_PERMISSIONS = [...cwopaPermissions];
const DRAFT_PERMISSIONS = [PERMISSIONS.ROLE_BMS_SUPERUSER];
const DELETE_PERMISSIONS = [PERMISSIONS.ROLE_BMS_SUPERUSER];
const CRITICAL_FINDING_PERMISSIONS = [PERMISSIONS.ROLE_BMS_SUPERUSER];

export const BRIDGE_PROBLEM_REPORT_PERMISSIONS = {
  [ACCESS_LEVELS.VIEW]: VIEW_PERMISSIONS,
  [ACCESS_LEVELS.EDIT]: EDIT_PERMISSIONS,
  [ACCESS_LEVELS.ADD]: ADD_PERMISSIONS,
  [ACCESS_LEVELS.DRAFT]: DRAFT_PERMISSIONS,
  [ACCESS_LEVELS.DELETE]: DELETE_PERMISSIONS,
  [ACCESS_LEVELS.CUSTOM.BPR_CRITICAL_FINDING]: CRITICAL_FINDING_PERMISSIONS,
  [ACCESS_LEVELS.CUSTOM.BPR_EDIT_IN_NON_DRAFT_STATE]: [
    PERMISSIONS.ROLE_BMS_SUPERUSER,
  ],
};
