<template>
  <v-data-table
    :id="`table_eDocs_${id}`"
    :items="docs"
    return-object
    v-model:sort-by="sortBy"
    class="lessPaddingTableColumns"
    :items-per-page="-1"
    :headers="headers"
    hide-default-footer
  >
    <template v-slot:[`header.checkbox`]>
      <v-checkbox
        class="d-flex justify-center"
        :id="`${id}_checkbox_selectAll`"
        v-model="selectAll"
        @click="handleSelectAll"
        hide-details
      ></v-checkbox>
    </template>
    <template
      v-if="id == 'inspection'"
      v-slot:[`header.inspectionDate`]="{ column }"
    >
      <DataTableHeader :sortBy="sortBy" :column="column" :lines="3" />
    </template>
    <template v-slot:[`header.description`]="{ column }">
      <DataTableHeader :sortBy="sortBy" :column="column" :lines="3" />
    </template>
    <template v-slot:[`header.type`]="{ column }">
      <DataTableHeader :sortBy="sortBy" :column="column" :lines="3" />
    </template>
    <template v-slot:[`header.source`]="{ column }">
      <DataTableHeader :sortBy="sortBy" :column="column" :lines="3" />
    </template>
    <template v-slot:[`header.lastModDate`]="{ column }">
      <DataTableHeader :sortBy="sortBy" :column="column" :lines="3" />
    </template>
    <template v-slot:[`header.download`]="{ column }">
      <DataTableHeader :sortBy="sortBy" :column="column" :lines="3" />
    </template>
    <template v-slot:item="{ item }">
      <tr :class="rowClass(item)">
        <td class="alignCenter">
          <v-checkbox
            class="d-flex justify-center"
            :id="`${id}_checkbox_selectDoc_${item.label}`"
            v-model="item.isSelected"
            @click="handleSelectRow(item)"
            hide-details
            :disabled="!connectivity.getisOnline && !item.content"
          ></v-checkbox>
        </td>
        <td v-if="id == 'inspection'" class="alignCenter">
          {{ getFormattedDateStringNoTime(item.inspectionDate) }}
        </td>
        <td class="alignCenter">
          <span v-if="item.content"
            ><a @click.prevent="downloadItem(item)" target="_blank">
              <div style="overflow-wrap: anywhere" v-if="item.label">
                {{ item.label }}
              </div>
              <IconTooltip
                v-else
                id="icon_viewFile"
                icon="fa-solid fa-file"
                tooltip="Click to View"
                styleClass="margin-left:0px !important"
              />
            </a>
          </span>
          <span v-else
            ><template v-if="item.label"
              ><div style="overflow-wrap: anywhere" v-if="item.label">
                {{ item.label }}
              </div></template
            ><template v-else>
              <IconTooltip
                id="icon_downloadFile"
                icon="fa-regular fa-file"
                tooltip="Download to View"
                styleClass="margin-left:0px !important"
              /> </template
          ></span>
        </td>
        <td class="alignCenter">
          {{ item.type }}
        </td>
        <td class="alignCenter">
          {{ item.source }}
        </td>
        <td class="alignCenter">
          {{ getFormattedDateStringNoTime(item.lastModDate) }}
        </td>
        <td class="alignCenter">
          <v-progress-linear
            striped
            height="5"
            color="secondary"
            indeterminate
            v-if="item.download === DOWNLOAD_PROGRESS.START"
          />
          <IconTooltip
            :id="`icon_success_${id}`"
            v-if="item.download === DOWNLOAD_PROGRESS.SUCCESS || item.content"
            icon="fa-solid fa-circle-check"
            tooltip="Success"
            iconclass="successIcon"
          />
          <IconTooltip
            :id="`icon_fail_${id}`"
            v-else-if="item.download === DOWNLOAD_PROGRESS.FAIL"
            icon="fa-solid fa-circle-xmark"
            :tooltip="item.error"
            iconclass="failIcon"
          />
        </td>
      </tr>
    </template>
    <template v-slot:bottom> </template>
  </v-data-table>
</template>
<script>
import { ref, computed, watch } from "vue";
import { base64ToArrayBuffer } from "@/util/downloadFile";
import { DOWNLOAD_PROGRESS } from "@/constants/Inspections";
import { useConnectivityStore } from "@/stores/connectivity";
import { storeToRefs } from "pinia";
import IconTooltip from "@/components/common/IconTooltip.vue";
import useDateField from "@/composables/dateField";
import DataTableHeader from "@/components/common/DataTableHeader.vue";

export default {
  name: "EDocsTable",
  props: { eDocsList: { default: [] }, id: { default: "" } },
  setup(props) {
    let sortBy = ref();
    if (props.id == "inspection") {
      sortBy.value = [{ key: "inspectionDate", order: "asc" }];
    } else {
      sortBy.value = [{ key: "type", order: "asc" }];
    }
    let headers = ref();
    if (props.id == "inspection") {
      headers.value = [
        {
          key: "checkbox",
          width: "5%",
        },
        {
          line1: "Inspection",
          line2: "Date",
          line3: null,
          sortable: true,
          key: "inspectionDate",
          width: "15%",
        },
        {
          line1: null,
          line2: "Description/Document Label",
          line3: null,
          sortable: false,
          key: "description",
          width: "35%",
        },
        {
          line1: null,
          line2: "Type",
          line3: null,
          sortable: true,
          key: "type",
          width: "10%",
        },
        {
          line1: null,
          line2: "Source",
          line3: null,
          sortable: false,
          key: "source",
          width: "10%",
        },
        {
          line1: "Last ",
          line2: "Modified",
          line3: "Date",
          sortable: false,
          key: "lastModDate",
          width: "15%",
        },
        {
          line1: null,
          line2: "Download",
          line3: null,
          sortable: false,
          key: "download",
          width: "10%",
        },
      ];
    } else {
      headers.value = [
        {
          key: "checkbox",
          width: "5%",
        },
        {
          line1: null,
          line2: "Description/Document Label",
          line3: null,
          sortable: false,
          key: "description",
          width: "35%",
        },
        {
          line1: null,
          line2: "Type",
          line3: null,
          sortable: true,
          key: "type",
          width: "20%",
        },
        {
          line1: null,
          line2: "Source",
          line3: null,
          sortable: false,
          key: "source",
          width: "15%",
        },
        {
          line1: "Last ",
          line2: "Modified",
          line3: "Date",
          sortable: false,
          key: "lastModDate",
          width: "15%",
        },
        {
          line1: null,
          line2: "Download",
          line3: null,
          sortable: false,
          key: "download",
          width: "10%",
        },
      ];
    }

    const selectedDocs = ref();
    const docs = computed(() => props.eDocsList);
    let selectAll = ref(false);
    const connectivity = useConnectivityStore();
    const { getFormattedDateStringNoTime } = useDateField();
    const downloadItem = ({ label, content, contentType, fileName }) => {
      const blob = new Blob([base64ToArrayBuffer(content)], {
        type: contentType,
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label || fileName;
      link.click();
      URL.revokeObjectURL(link.href);
    };
    const clearSelections = () => {
      selectedDocs.value = [];
      selectAll.value = false;
      docs.value.forEach((doc) => (doc.isSelected = false));
    };

    const setDownloadProgress = (invDocs) => {
      docs.value.forEach((d) => {
        const doc = invDocs?.find(
          (i) =>
            i.docId == d.docId && i.docContainerSeqNum == d.docContainerSeqNum
        );
        d.download = doc?.download;
        d.error = doc?.error;
      });
    };

    const handleSelectAll = () => {
      if (!selectAll.value) {
        docs.value.forEach((doc) => {
          if (!connectivity.getisOnline) {
            //If not online - select only the docs that are already downloaded
            if (doc.content) {
              doc.isSelected = true;
            }
          } else {
            doc.isSelected = true;
          }
        });
      } else {
        docs.value.forEach((doc) => (doc.isSelected = false));
        selectedDocs.value = [];
      }
      updateSelection();
    };

    const handleSelectRow = (doc) => {
      if (!doc.isSelected) {
        doc.isSelected = true;
      } else {
        doc.isSelected = false;
        selectAll.value = false;
      }
      updateSelection();
    };

    const updateSelection = () => {
      selectedDocs.value = [];
      docs.value.map((doc) => {
        if (doc.isSelected) {
          selectedDocs.value.push(doc);
        }
        return doc;
      });
    };
    const rowClass = (data) => {
      return selectedDocs.value?.some(
        (d) =>
          d.docContainerSeqNum == data.docContainerSeqNum &&
          d.docId == data.docId
      )
        ? "rowSelect"
        : "rowUnselect";
    };

    const { isOnline } = storeToRefs(connectivity);
    watch(isOnline, () => {
      docs.value.forEach((doc) => {
        if (!isOnline.value) {
          //If not online - select only the docs that are already selected and downloaded
          doc.isSelected = doc.isSelected && doc.content != null;
          //if atleast one doc is not selected set the selectAll checkbox to false
          if (!doc.isSelected) {
            selectAll.value = false;
          }
        }
      });
      updateSelection();
    });

    return {
      sortBy,
      headers,
      selectedDocs,
      downloadItem,
      clearSelections,
      DOWNLOAD_PROGRESS,
      docs,
      setDownloadProgress,
      selectAll,
      handleSelectAll,
      handleSelectRow,
      connectivity,
      getFormattedDateStringNoTime,
      rowClass,
    };
  },
  components: { IconTooltip, DataTableHeader },
};
</script>
<style scoped lang="scss">
.alignCenter {
  text-align: center;
}
</style>
