export const INVENTORY_LINKS_PAGES = {
  RISK_SCORE_DETAILS: "Risk Score Details",
  STRUCTURE_IDENTIFICATION: "Structure Identification",
  STRUCTURE_COMPOSITION: "Structure Composition",
  SIGNS_AND_LIGHTS: "Signs & Lights",
  WALLS: "Walls",
  TUNNELS: "Tunnels",
  FEATURES: "Features",
  INSPECTION_PLANNING: "Inspection Planning",
  PRESERVATION_REHAB: "Preservation & Rehab",
};

export const INVENTORY_ROUTES = {
  RISK_SCORE_DETAILS_ROUTE: "/inventoryLinks/riskScoreDetails",
  STRUCTURE_IDENTIFICATION_ROUTE: "/inventoryLinks/structureIdentification",
  STRUCTURE_COMPOSITION: "/inventoryLinks/structureComposition",
  SIGNS_AND_LIGHTS: "/inventoryLinks/signAndLights",
  WALLS: "/inventoryLinks/walls",
  TUNNELS: "/inventoryLinks/tunnels",
  FEATURES: "/inventoryLinks/features",
  INSPECTION_PLANNING: "/inventoryLinks/inspectionPlanning",
  PRESERVATION_REHAB: "/inventoryLinks/preservationRehab",
};

export const LATLONGVIEW = {
  DECIMALS: "Decimals",
  DMS: "DMS",
};

export const STRUCTURE_IDENTIFICATION_MESSAGES = {
  MAINT_RESP_DELETE_CONFIRM:
    "Are you sure you want to delete this Maintenance Responsibility item?",
  STRUCTURE_GROUP_DELETE_CONFIRM:
    "Are you sure you want to delete this Structure Group?",
  GROUP_STRUCTURE_DELETE_CONFIRM:
    "Are you sure you want to delete this Structure from the group?",
};

export const INSPECTION_PLANNING_MESSAGES = {
  PERMIT_DELETE_CONFIRM: "Are you sure you want to delete this Permit?",
};

export const STRUCTURE_COMPOSITION_MESSAGES = {
  PROTECTIVE_COATING_DELETE_CONFIRM:
    "Are you sure you want to delete this Protective Coating item?",
  SPAN_SET_DELETE_CONFIRM:
    "Are you sure you want to delete this Span Set record?",
  SUBSTRUCTURE_SET_DELETE_CONFIRM:
    "Are you sure you want to delete this Substructure Set?",
  SPAN_DELETE_CONFIRM: "Are you sure you want to delete this Spans record?",
  SUBUNIT_DELETE_CONFIRM: "Are you sure you want to delete this Subunit?",
  SPANSET_SEQUENTIAL_ERROR_MESSAGE:
    "Span Sets must be in sequential order, please re-order before saving.",
  SPANSET_DUPLICATE_ERROR_MESSAGE: "{configDesignationId} already exists",
  SPANSET_ONLY_ONE_MAIN_SET:
    "Only one span set per structure can have Main Span Set Indicator set to Y.",
  SPANSET_ATLEAST_ONE_MAIN_SET:
    "Each structure must have one span set, set as the Main Span Set.",
  SUBSTRUCTURESET_SEQUENTIAL_ERROR_MESSAGE:
    "Substructure Sets must be in sequential order, please re-order before saving.",
  SUBSTRUCTURESET_DUPLICATE_ERROR_MESSAGE:
    "{configDesignationId} already exists",
};

export const FEATURE_MESSAGES = {
  FEATURE_TYPE_SEQUENTIAL_ERROR_MESSAGE:
    "{featureType} must be in sequential order, please re-order before saving.",
  FEATURE_TYPE_DUPLICATE_ERROR_MESSAGE: "{featureTypeNumber} already exists",
  FEATURE_DELETE_CONFIRM:
    "Are you sure you want to delete this {featureType} feature item?",
  CROSSING_ID_NOT_IN_GC:
    "FR05 AAR DOT Number must be a valid Crossing ID in the Grade Crossing database",
};

export const PRESERVATION_REHAB_MESSAGES = {
  PROJECT_RECORD_DELETE_CONFIRM:
    "Are you sure you want to delete this Project Record?",
  CONTRACTOR_DELETE_CONFIRM: "Are you sure you want to delete this Contractor?",
  JOINT_DELETE_CONFIRM:
    "Are you sure you want to delete this Joint Information?",
  BEARING_DELETE_CONFIRM:
    "Are you sure you want to delete this Bearing Information?",
};
