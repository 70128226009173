<template>
  <v-row>
    <v-col class="px-0">
      <v-btn
        :disabled="!isEditable || expandedRowsDrawings?.length > 0"
        id="btn_addDrawing"
        variant="outlined"
        class="my-2 ml-3"
        @click="addDrawing()"
        >Add</v-btn
      >
    </v-col>
  </v-row>
  <v-row class="mb-8">
    <v-col cols="12">
      <v-data-table
        class="noScrollTable compactTable"
        :items="drawings"
        :headers="drawingsHeaders"
        v-model:expanded="expandedRowsDrawings"
        hide-default-footer
        :items-per-page="-1"
        show-expand
        return-object
      >
        <template v-slot:[`header.data-table-expand`]="{ column }">
          {{ column.title }}
        </template>
        <template v-slot:[`header.DRAWING_TYPE`]="{ column }">
          <DataTableHeader :column="column" :lines="1" />
        </template>
        <template v-slot:[`header.DRAWING_NUM`]="{ column }">
          <DataTableHeader :column="column" :lines="1" />
        </template>
        <template v-slot:[`header.DRAWING_DESC`]="{ column }">
          <DataTableHeader :column="column" :lines="1" />
        </template>
        <template v-slot:[`header.Action`]="{ column }">
          <DataTableHeader :column="column" :lines="1" />
        </template>
        <template v-slot:item="{ item }">
          <tr :class="rowClassDrawing(item)">
            <td class="text-center">
              <template v-if="!anyEditableDrawings">
                <button
                  v-if="!isExpandedDrawing(item)"
                  @click="toggleDrawingRow(item)"
                >
                  <v-icon
                    :id="`icon_Chevron_Right_${item.DRAWING_KEY}`"
                    icon="fa-solid fa-chevron-right"
                  ></v-icon>
                </button>

                <button
                  v-if="isExpandedDrawing(item)"
                  @click="toggleDrawingRow(item)"
                >
                  <v-icon
                    :id="`icon_Chevron_Down_${item.DRAWING_KEY}`"
                    icon="fa-solid fa-chevron-down"
                  ></v-icon>
                </button>
              </template>
            </td>
            <td class="alignCenter">
              {{
                item.DRAWING_TYPE
                  ? configStore.getReferenceValue(
                      REFERENCE_TABLE.DRAWING_TYPE,
                      item.DRAWING_TYPE,
                      true
                    )
                  : ""
              }}
            </td>
            <td class="alignCenter">
              {{ item?.DRAWING_NUM }}
            </td>
            <td class="alignCenter">
              {{ item?.DRAWING_DESC }}
            </td>
            <td class="alignCenter">
              <div
                style="white-space: nowrap"
                v-if="
                  isExpandedDrawing(item) && anyEditableDrawings && isEditable
                "
              >
                <IconTooltip
                  id="icon_add"
                  icon="fa-solid fa-check"
                  :tooltip="item?.isAdd ? 'Add' : 'Update'"
                  @click="saveDrawing(item)"
                />
                <IconTooltip
                  id="icon_close"
                  icon="fa-solid fa-xmark"
                  tooltip="Close"
                  @click="closeDrawing"
                />
              </div>
              <div
                style="white-space: nowrap"
                v-else-if="
                  isEditable &&
                  (expandedRowsDrawings?.length == 0 || !anyEditableDrawings)
                "
              >
                <IconTooltip
                  id="icon_edit"
                  icon="fa-solid fa-pencil"
                  tooltip="Edit"
                  @click="editDrawing(item)"
                />
                <IconTooltip
                  id="icon_delete"
                  icon="fa-solid fa-trash"
                  tooltip="Delete"
                  @click="deleteDrawing(item)"
                />
              </div>
            </td>
          </tr>
        </template>
        <template v-slot:expanded-row="{ item }">
          <td :colspan="drawingsHeaders.length">
            <v-form
              ref="drawingsForm"
              v-model="validDrawings"
              class="mt-2"
              @change="changesDetectedDrawing = true"
            >
              <v-row>
                <v-col
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  cols="12"
                  class="px-0 pt-3"
                >
                  <LabelSelect
                    labelXl="4"
                    labelLg="6"
                    labelMd="3"
                    labelSm="4"
                    selectXl="8"
                    selectLg="6"
                    selectMd="4"
                    selectSm="5"
                    :id="`drawingType_${item.DRAWING_KEY}`"
                    label="VN05 Drawing Type"
                    isRequired
                    :options="drawingTypes"
                    :isEditable="isEditable && !item.readOnly"
                    v-model="item.DRAWING_TYPE"
                    @update:modelValue="
                      {
                        changesDetectedDrawing = true;
                      }
                    "
                  />
                </v-col>
                <v-col
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  cols="12"
                  :class="
                    $vuetify?.display?.lgAndUp ? 'pl-3 pr-0 pt-3' : 'px-0 pt-3'
                  "
                >
                  <LabelText
                    labelXl="4"
                    labelLg="6"
                    labelMd="3"
                    labelSm="4"
                    textXl="8"
                    textLg="6"
                    textMd="4"
                    textSm="5"
                    isRequired
                    :id="`drawingNumber_${item.DRAWING_KEY}`"
                    label="VN06 Drawing Number"
                    :counter="null"
                    v-model="item.DRAWING_NUM"
                    :style="isEditable ? '' : 'padding-top: 2px'"
                    :isEditable="isEditable && !item.readOnly"
                    @update:modelValue="
                      {
                        changesDetectedDrawing = true;
                      }
                    "
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="px-0 pt-3">
                  <LabelText
                    labelXl="2"
                    labelLg="3"
                    labelMd="3"
                    labelSm="4"
                    textXl="10"
                    textLg="9"
                    textMd="9"
                    textSm="8"
                    type="textarea"
                    maxlength="255"
                    :id="`description_${item.DRAWING_KEY}`"
                    label="VN07 Description"
                    v-model="item.DRAWING_DESC"
                    :isEditable="isEditable && !item.readOnly"
                    @update:modelValue="
                      {
                        changesDetectedDrawing = true;
                      }
                    "
                  />
                </v-col>
              </v-row>
            </v-form>
          </td>
        </template>
        <template v-slot:bottom> </template>
      </v-data-table>
    </v-col>
  </v-row>
  <ConfirmDialog ref="closeConfirm" :cancelButtonText="BUTTONS.NO" />
</template>
<script>
import { computed, ref, watch, nextTick, onUnmounted } from "vue";
import { useInspectionStore } from "@/stores/inspection";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { LOGGER } from "@/util/logger";
import {
  BUTTONS,
  COMMON_MESSAGE_CONSTANTS,
} from "@/constants/CommonWebConstants";
import DataTableHeader from "@/components/common/DataTableHeader.vue";
import { clone } from "@/util/clone";
import {
  INSPECTION_MESSAGES,
  INSPECTION_DELETE_CONFIRM_MESSAGES,
} from "@/constants/InspectionConstants";
import { useSnackbarStore } from "@/stores/snackbar";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import IconTooltip from "@/components/common/IconTooltip.vue";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelText from "@/components/common/LabelText.vue";
import { useConfigStore } from "@/stores/config";

export default {
  name: "DrawingsTable",
  setup() {
    const configStore = useConfigStore();

    const drawingTypes = computed(() =>
      configStore.getReferenceValues(
        REFERENCE_TABLE.DRAWING_TYPE,
        null,
        null,
        true
      )
    );

    const inspectionStore = useInspectionStore();

    const structure = computed(() => inspectionStore.selectedInspection);

    const isEditable = computed(() => {
      return inspectionStore.isEditable;
    });

    let drawingsForm = ref(null);

    let validDrawings = ref(false);

    const closeConfirm = ref(null);

    let snackBarId = ref("");
    let changesDiscardedId = ref("");
    const snackbarStore = useSnackbarStore();

    onUnmounted(() => {
      snackbarStore.removeMessage(snackBarId.value);
    });
    const drawingsHeaders = ref([
      { title: "", key: "data-table-expand" },
      {
        line1: "VN05",
        line2: "Drawing Type",
        line3: null,
        sortable: false,
        key: "DRAWING_TYPE",
      },
      {
        line1: "VN06",
        line2: "Drawing Number",
        line3: null,
        sortable: false,
        key: "DRAWING_NUM",
      },
      {
        line1: "VN07",
        line2: "Description",
        line3: null,
        sortable: false,
        key: "DRAWING_DESC",
      },
      {
        line1: null,
        line2: "Action",
        line3: null,
        sortable: false,
        key: "Action",
      },
    ]);

    let expandedRowsDrawings = ref([]);

    let changesDetectedDrawing = ref(false);

    let drawings = ref([]);

    const getNewDrawing = () => {
      return {
        BRKEY: structure.value.Bridge.BRKEY,
        DRAWING_KEY: drawings.value.length + 1,
        DRAWING_TYPE: "",
        DRAWING_NUM: "",
        MODTIME: new Date().toISOString(),
        USERKEY: structure.value.InspEvnt.USERKEY,
        DRAWING_DESC: null,
      };
    };

    const convertToDrawingObject = (detail) => {
      return {
        BRKEY: detail.BRKEY,
        DRAWING_KEY: detail.DRAWING_KEY,
        DRAWING_TYPE: detail.DRAWING_TYPE,
        DRAWING_NUM: detail.DRAWING_NUM,
        MODTIME: detail.MODTIME,
        USERKEY: detail.USERKEY,
        DRAWING_DESC: detail.DRAWING_DESC,
      };
    };

    const getDrawings = () => {
      expandedRowsDrawings.value = [];
      if (!structure.value.T_Drawing?.[0]) {
        //remove null values from array(if any)
        structure.value.T_Drawing = structure.value.T_Drawing?.filter(
          (a) => !!a
        );
      }

      drawings.value = clone(structure.value.T_Drawing)?.map((i) => {
        return { ...i, readOnly: true };
      });
    };
    getDrawings();

    const rowClassDrawing = (data) => {
      return expandedRowsDrawings.value?.find(
        (a) => a.DRAWING_KEY === data.DRAWING_KEY
      )
        ? "rowSelect"
        : "rowUnselect";
    };

    const closeDrawing = () => {
      if (isEditable.value && changesDetectedDrawing.value) {
        //show confirmation dialog on closing
        closeConfirm.value
          .open(
            INSPECTION_MESSAGES.DETECT_DATATABLE_CHANGE_CONFIRMATION_MESSAGE,
            BUTTONS.YES
          )
          .then((response) => {
            if (!response) {
              getDrawings();
              expandedRowsDrawings.value = [];
              changesDetectedDrawing.value = false;
              inspectionStore.setAddingOrEditing(false);
              snackbarStore.removeMessage(snackBarId.value);
              changesDiscardedId.value = snackbarStore.showMessage({
                displayText: COMMON_MESSAGE_CONSTANTS.CHANGES_DISCARDED,
                timeout: 10000,
              });
            }
          });
      } else {
        getDrawings();
        expandedRowsDrawings.value = [];
        inspectionStore.setAddingOrEditing(false);
        snackbarStore.removeMessage(snackBarId.value);
      }
    };

    const isExpandedDrawing = (details) => {
      return (
        expandedRowsDrawings.value?.findIndex(
          (a) => a.DRAWING_KEY == details.DRAWING_KEY
        ) >= 0
      );
    };

    function editDrawing(detail) {
      inspectionStore.setAddingOrEditing(true);
      snackBarId.value = snackbarStore.showMessage({
        displayText: INSPECTION_MESSAGES.DRAWINGS_DATATABLE,
      });
      detail.readOnly = false;
      expandedRowsDrawings.value = [detail];
      changesDetectedDrawing.value = false;
    }

    function deleteDrawing(detail) {
      closeConfirm.value
        .open(
          INSPECTION_DELETE_CONFIRM_MESSAGES.EDOCS_DRAWING_DELETE_CONFIRM_MESSAGE,
          BUTTONS.YES
        )
        .then((response) => {
          if (response) {
            const deleteIndex = structure.value.T_Drawing.findIndex(
              (a) => a?.DRAWING_KEY === detail.DRAWING_KEY
            );
            if (deleteIndex > -1) {
              structure.value.T_Drawing.splice(deleteIndex, 1);
              expandedRowsDrawings.value = [];
              getDrawings();
            }
          } else {
            expandedRowsDrawings.value = [];
          }
        });
    }

    const anyEditableDrawings = computed(() => {
      return expandedRowsDrawings.value?.find((element) => !element.readOnly);
    });

    const toggleDrawingRow = (detail) => {
      if (expandedRowsDrawings.value?.includes(detail)) {
        const index = expandedRowsDrawings.value.findIndex(
          (a) => detail.DRAWING_KEY == a.DRAWING_KEY
        );
        expandedRowsDrawings.value.splice(index, 1);
      } else {
        expandedRowsDrawings.value.push(detail);
      }
    };

    function addDrawing() {
      inspectionStore.setAddingOrEditing(true);
      snackBarId.value = snackbarStore.showMessage({
        displayText: INSPECTION_MESSAGES.DRAWINGS_DATATABLE,
      });
      let newDrawing = getNewDrawing();
      newDrawing.isAdd = true;
      newDrawing.readOnly = false;
      drawings.value.unshift(newDrawing);
      expandedRowsDrawings.value = [newDrawing];
      changesDetectedDrawing.value = false;
      nextTick(() => {
        drawingsForm.value?.validate();
      });
    }

    function saveDrawing(detail) {
      drawingsForm.value
        .validate()
        .then(() => {
          if (validDrawings.value) {
            if (!structure.value?.T_Drawing?.[0]) {
              //remove null values from array(if any)
              structure.value.T_Drawing = structure.value.T_Drawing?.filter(
                (a) => !!a
              );
            }
            if (detail.isAdd) {
              structure.value.T_Drawing.push(convertToDrawingObject(detail));
            } else {
              //update existing
              const updateIndex = drawings.value.findIndex(
                (a) => a?.DRAWING_KEY === detail.DRAWING_KEY
              );
              structure.value.T_Drawing.splice(
                updateIndex,
                1,
                convertToDrawingObject(detail)
              );
            }
            //if all required fields entered - close the expanded row
            expandedRowsDrawings.value = [];
            getDrawings();
            changesDetectedDrawing.value = false;
            inspectionStore.setAddingOrEditing(false);
            inspectionStore.setShowValidationSnackbar(false);
            snackbarStore.removeMessage(snackBarId.value);
          } else {
            inspectionStore.setShowValidationSnackbar(true);
          }
        })
        .catch((err) => LOGGER.logException(err));
    }

    watch(
      () => [structure.value.T_Drawing],
      () => {
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );

    return {
      COMMON_MESSAGE_CONSTANTS,
      drawingTypes,
      configStore,
      getDrawings,
      validDrawings,
      drawingsForm,
      expandedRowsDrawings,
      inspectionStore,
      rowClassDrawing,
      closeDrawing,
      isExpandedDrawing,
      editDrawing,
      deleteDrawing,
      anyEditableDrawings,
      toggleDrawingRow,
      addDrawing,
      saveDrawing,
      closeConfirm,
      INSPECTION_MESSAGES,
      BUTTONS,
      changesDetectedDrawing,
      drawingsHeaders,
      drawings,
      REFERENCE_TABLE,
      isEditable,
    };
  },
  components: {
    IconTooltip,
    DataTableHeader,
    ConfirmDialog,
    LabelText,
    LabelSelect,
  },
};
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;
.alignCenter {
  text-align: center;
}
.v-data-table :deep(button:hover) {
  background-color: inherit !important;
  color: inherit !important;
}

.v-data-table :deep(button:focus) {
  outline: none !important;
}
</style>
