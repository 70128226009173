import { PERMISSIONS } from "@/rbac/permissions.js";
import { ACCESS_LEVELS } from "@/rbac/accessLevels.js";

const VIEW_PERMISSIONS = [PERMISSIONS.STRUCTURESEARCH];



export const STRUCTURE_SEARCH_USER_PERMISSIONS = {
  [ACCESS_LEVELS.VIEW]: VIEW_PERMISSIONS,
};
