import { defineStore } from "pinia";
import { ref } from "vue";

export const useDeckStore = defineStore("deck", () => {
  let deckData = ref({ idb: {}, service: {} });

  const getDeckObject = (sourceType, brkey, structure) => {
    if (sourceType === "idb") {
      //if source type is pouchDB
      convertToInspModuleObject(structure);
    } else if (sourceType === "service") {
      //if sourcetype is service call
      convertToInspLinkObject(brkey);
    }
    return deckData.value[sourceType];
  };

  const getSaveDeckObject = (sourceType, deckValue, structure) => {
    if (sourceType === "idb") {
      //if source type is pouchDB
      return convertToSaveInspModuleObject(deckValue, structure);
    } else if (sourceType === "service") {
      //if sourcetype is service call
      return deckValue;
    }
  };

  const convertToSaveInspModuleObject = (deckValue, structure) => {
    structure.T_Insp_Condition_Hist.DK_DURATION = deckValue.DK_DURATION;
    structure.T_Insp_Condition_Hist.DK_DURATION_CHANGES =
      deckValue.DK_DURATION_CHANGES;
    structure.InspEvnt.DKRATING = deckValue.DKRATING;

    structure.T_Insp_Condition_Hist.DECK_WS_SURF_DURATION =
      deckValue.DECK_WS_SURF_DURATION;

    structure.T_Insp_Condition_Hist.DECK_WS_SURF_DURATION_CHANGES =
      deckValue.DECK_WS_SURF_DURATION_CHANGES;
    structure.UserInsp.DECK_WS_COND_RATE = deckValue.DECK_WS_COND_RATE;
    structure.UserInsp.EST_CHLORIDE_CONTENT_PCT =
      deckValue.EST_CHLORIDE_CONTENT_PCT;
    structure.UserInsp.EST_CHLORIDE_CONTENT_DATE =
      deckValue.EST_CHLORIDE_CONTENT_DATE;
    structure.Bridge.DKSURFTYPE = deckValue.DKSURFTYPE;
    structure.UserBrdg.APPR_DKSURFTYPE = deckValue.APPR_DKSURFTYPE;
    structure.Bridge.DKMEMBTYPE = deckValue.DKMEMBTYPE;
    structure.UserBrdg.APPR_DKMEMBTYPE = deckValue.APPR_DKMEMBTYPE;
    structure.Bridge.DKPROTECT = deckValue.DKPROTECT;
    structure.UserBrdg.APPR_DKPROTECT = deckValue.APPR_DKPROTECT;
    structure.UserBrdg.MAIN_WS_THICKNESS = deckValue.MAIN_WS_THICKNESS;
    structure.UserBrdg.APPR_WS_THICKNESS = deckValue.APPR_WS_THICKNESS;
    structure.UserBrdg.MAIN_WS_THICK_DATE = deckValue.MAIN_WS_THICK_DATE;
    structure.UserBrdg.APPR_WS_THICK_DATE = deckValue.APPR_WS_THICK_DATE;
    structure.UserBrdg.PROTECT_YEAR = deckValue.PROTECT_YEAR;
    structure.UserBrdg.PROTECT_NOTE = deckValue.PROTECT_NOTE;
    structure.UserInsp.DECK_CRACKING_METRIC = deckValue.DECK_CRACKING_METRIC;
    structure.UserBrdg.MAX_STRUCTURE_HEIGHT = deckValue.MAX_STRUCTURE_HEIGHT;
    structure.Bridge.DKSTRUCTYP = deckValue.DKSTRUCTYP;
    structure.Bridge.DECKWIDTH = deckValue.DECKWIDTH;
    structure.Bridge.LFTCURBSW = deckValue.LFTCURBSW;
    structure.Bridge.RTCURBSW = deckValue.RTCURBSW;
    structure.Bridge.BRIDGEMED = deckValue.BRIDGEMED;
  };

  const convertToInspModuleObject = (structure) => {
    deckData.value.service = {};
    const inspDeck = {
      DK_DURATION: structure?.T_Insp_Condition_Hist?.DK_DURATION,
      DK_DURATION_CHANGES:
        structure?.T_Insp_Condition_Hist?.DK_DURATION_CHANGES,
      DKRATING: structure?.InspEvnt?.DKRATING,
      DECK_WS_SURF_DURATION:
        structure?.T_Insp_Condition_Hist?.DECK_WS_SURF_DURATION,
      DECK_WS_SURF_DURATION_CHANGES:
        structure?.T_Insp_Condition_Hist?.DECK_WS_SURF_DURATION_CHANGES,
      DECK_WS_COND_RATE: structure?.UserInsp?.DECK_WS_COND_RATE,
      EST_CHLORIDE_CONTENT_PCT: structure.UserInsp.EST_CHLORIDE_CONTENT_PCT,
      EST_CHLORIDE_CONTENT_DATE: structure.UserInsp.EST_CHLORIDE_CONTENT_DATE,
      DKSURFTYPE: structure.Bridge.DKSURFTYPE,
      APPR_DKSURFTYPE: structure.UserBrdg.APPR_DKSURFTYPE,
      DKMEMBTYPE: structure.Bridge.DKMEMBTYPE,
      APPR_DKMEMBTYPE: structure.UserBrdg.APPR_DKMEMBTYPE,
      DKPROTECT: structure.Bridge.DKPROTECT,
      APPR_DKPROTECT: structure.UserBrdg.APPR_DKPROTECT,
      MAIN_WS_THICKNESS: structure.UserBrdg.MAIN_WS_THICKNESS,
      APPR_WS_THICKNESS: structure.UserBrdg.APPR_WS_THICKNESS,
      MAIN_WS_THICK_DATE: structure.UserBrdg.MAIN_WS_THICK_DATE,
      APPR_WS_THICK_DATE: structure.UserBrdg.APPR_WS_THICK_DATE,
      PROTECT_YEAR: structure.UserBrdg.PROTECT_YEAR,
      PROTECT_NOTE: structure.UserBrdg.PROTECT_NOTE,
      DECK_CRACKING_METRIC: structure.UserInsp.DECK_CRACKING_METRIC,
      MAX_STRUCTURE_HEIGHT: structure.UserBrdg.MAX_STRUCTURE_HEIGHT,
      DKSTRUCTYP: structure.Bridge.DKSTRUCTYP,
      DECKWIDTH: structure.Bridge.DECKWIDTH,
      LFTCURBSW: structure.Bridge.LFTCURBSW,
      RTCURBSW: structure.Bridge.RTCURBSW,
      BRIDGEMED: structure.Bridge.BRIDGEMED,
    };
    deckData.value.idb = { ...inspDeck };
  };

  const convertToInspLinkObject = (brkey) => {
    brkey;
    //clear idb object
    deckData.value.idb = {};

    //TODO make network call for deck Object
    let response = {
      deckDTO: {
        DK_DURATION: 1,
        DK_DURATION_CHANGES: 1,
        DKRATING: "1",
        DECK_WS_SURF_DURATION: "1",
        DECK_WS_SURF_DURATION_CHANGES: "1",
        DECK_WS_COND_RATE: "1",
        EST_CHLORIDE_CONTENT_PCT: 10,
        EST_CHLORIDE_CONTENT_DATE: null,
        DKSURFTYPE: "1",
        APPR_DKSURFTYPE: "1",
        DKMEMBTYPE: "1",
        APPR_DKMEMBTYPE: "1",
        DKPROTECT: "1",
        APPR_DKPROTECT: "1",
        MAIN_WS_THICKNESS: 1,
        APPR_WS_THICKNESS: 1,
        MAIN_WS_THICK_DATE: null,
        APPR_WS_THICK_DATE: null,
        PROTECT_YEAR: "2024",
        PROTECT_NOTE: "",
        DECK_CRACKING_METRIC: 1,
        MAX_STRUCTURE_HEIGHT: 1,
        DKSTRUCTYP: "1",
        DECKWIDTH: 1,
        LFTCURBSW: 1,
        RTCURBSW: 1,
        BRIDGEMED: "1",
        roadwayWidth: 7.2,
      },
    };
    deckData.value.service = response.deckDTO;
  };

  return { getDeckObject, getSaveDeckObject };
});
