<!--This is a custom component for Label Input Fields -->
<template>
  <div
    v-if="noGrid"
    style="display: flex"
    :class="
      (isRequired && isEditable) || readOnlyValidation
        ? 'verticalAlignment-top'
        : 'verticalAlignment-center'
    "
  >
    <!-- For required field error message the label is automatically centered. To prevent setting align to top and adding padding top-->
    <span
      v-if="label"
      :id="`${id}_No_Grid_Label`"
      :style="
        (isRequired && isEditable) || readOnlyValidation
          ? 'font-weight: bold; padding-top:2px'
          : 'font-weight: bold'
      "
      :class="isRequired ? 'required' : 'd-inline'"
      >{{ label }}<span v-if="showColon">:</span></span
    >
    <NumberField
      :class="noExtraPadding ? 'd-inline pl-1' : 'd-inline pl-3'"
      v-if="type === 'number'"
      :id="`number_${id}_No_Grid`"
      :readonly="!isEditable"
      :ariaLabel="ariaLabel"
      v-model="computedValue"
      :required="isRequired"
      :rules="computedRules"
      :maxlength="maxlength"
      :showSpinner="showSpinner"
      :decimalPlaces="decimalPlaces"
      :min="min"
      :appendWith="appendWith"
      :textStyle="textStyle"
      :pairCode="pairCode"
      :roundingFactor="roundingFactor"
      :showComma="showComma"
      :allowCommas="allowCommas"
      :showPlaceholder="showPlaceholder"
      :readOnlyValidation="readOnlyValidation"
      :conversionTypes="conversionTypes"
      :isDisabled="isDisabled"
      :alignRight="alignRight"
      :prependWithCurrencyIcon="prependWithCurrencyIcon"
    />
    <v-textarea
      :class="addTextAreaPadding ? 'pt-4 d-inline pl-1' : 'd-inline pl-1'"
      v-else-if="type === 'textarea'"
      :id="`textarea_${id}_No_Grid`"
      :aria-describedby="`textarea_${id}_No_Grid`"
      :aria-label="ariaLabel"
      :variant="!isEditable ? 'outlined' : 'filled'"
      :readonly="!isEditable"
      v-model="computedValue"
      hide-details="auto"
      :required="isRequired"
      :rules="computedRules"
      :maxlength="maxlength"
      :style="textStyle"
      :placeholder="placeholder"
      :counter="counter"
      :disabled="isDisabled"
      persistentCounter
      auto-grow
      rows="1"
      @keypress="handler"
      @update:model-value="truncate"
      @blur="trim"
    ></v-textarea>
    <v-text-field
      class="d-inline pl-1"
      v-else-if="type === 'text' && isEditable"
      :id="`text_${id}_No_Grid`"
      :aria-describedby="`text_${id}_No_Grid`"
      :aria-label="ariaLabel"
      variant="filled"
      :readonly="!isEditable"
      v-model="computedValue"
      hide-details="auto"
      :required="isRequired"
      :rules="computedRules"
      :maxlength="maxlength"
      :style="textStyle"
      :placeholder="placeholder"
      :counter="counter"
      :disabled="isDisabled"
      persistentCounter
      @blur="$emit('blur')"
      @update:model-value="$emit('input')"
      :type="password ? 'password' : 'text'"
      ><template v-if="appendWith" v-slot:append
        ><span>{{ appendWith }}</span></template
      >
      <template v-slot:prepend-inner v-if="prependIcon">
        <v-icon :icon="prependIcon" class="mr-2"
      /></template>

      <template v-slot:append-inner v-if="appendInnerIcon">
        <v-icon
          v-if="showGreenCheckIcon"
          :id="`icon_${id}`"
          color="green"
          :icon="iconValue"
          class="mr-2"
        />
        <v-icon
          v-else-if="showExclamationIcon"
          :id="`icon_${id}`"
          color="red"
          :icon="iconValue"
          class="mr-2"
        />
      </template>
    </v-text-field>
    <span v-else class="d-inline pl-4"
      ><v-icon v-if="prependIcon" :icon="prependIcon" class="mt-1 mr-2" />{{
        getDisplayValue()
      }}<span v-if="appendWith">{{ appendWith }}</span></span
    >
    <IconTooltip
      class="d-inline"
      v-if="showDurationIcon"
      :id="`icon_${id}`"
      :icon="iconValue"
      :tooltip="tooltip"
    />
    <small class="noBold d-inline">{{ afterText }}</small>
  </div>
  <v-row
    v-else-if="!noGrid && (type === 'number' || type === 'text')"
    class="verticalAlignment-center"
  >
    <v-col
      class="d-flex"
      :xl="labelXl"
      :lg="labelLg"
      :md="labelMd"
      :sm="labelSm"
      :cols="labelSm"
      v-if="label"
    >
      <v-label v-if="label" :id="`textOrNumber_${id}_Grid_Label`" class="bold"
        ><span :class="isRequired ? 'required' : ''">{{ label }}</span
        ><span v-if="showColon">:</span></v-label
      >
    </v-col>
    <v-col
      class="d-flex"
      :xl="textXl"
      :lg="textLg"
      :md="textMd"
      :sm="textSm"
      :cols="textSm"
    >
      <!--Adding padding to match non editable custom component spacings-->
      <span v-if="type === 'number' && !isEditable" class="pl-4" />
      <NumberField
        v-if="type === 'number'"
        :id="`number_${id}_Grid`"
        :readonly="!isEditable"
        v-model="computedValue"
        :ariaLabel="ariaLabel"
        :required="isRequired"
        :rules="computedRules"
        :maxlength="maxlength"
        :showSpinner="showSpinner"
        :decimalPlaces="decimalPlaces"
        :min="min"
        :maxValue="max"
        :appendWith="appendWith"
        :textStyle="textStyle"
        :pairCode="pairCode"
        :roundingFactor="roundingFactor"
        :showComma="showComma"
        :allowCommas="allowCommas"
        :showPlaceholder="showPlaceholder"
        :alignRight="alignRight"
        :noNegativeDisplayed="noNegativeDisplayed"
        :showMax="showMax"
        :readOnlyValidation="readOnlyValidation"
        :allowNegativeInput="allowNegativeInput"
        :allowDecimalInput="allowDecimalInput"
        :conversionTypes="conversionTypes"
        :isDisabled="isDisabled"
        :prependWithCurrencyIcon="prependWithCurrencyIcon"
      />
      <v-text-field
        v-else-if="type === 'text' && isEditable"
        :id="`text_${id}_Grid`"
        :aria-describedby="`text_${id}_Grid`"
        :aria-label="ariaLabel"
        variant="filled"
        :readonly="!isEditable"
        v-model="computedValue"
        hide-details="auto"
        :required="isRequired"
        :rules="computedRules"
        :maxlength="maxlength"
        :style="textStyle"
        :placeholder="placeholder"
        :counter="counter"
        :disabled="isDisabled"
        persistentCounter
        @blur="$emit('blur')"
        @update:model-value="$emit('input')"
        :type="password ? 'password' : 'text'"
        v-maska:unmaskedValue.unmasked="maska"
        ><template v-if="appendWith" v-slot:append
          ><span>{{ appendWith }}</span></template
        >
        <template v-slot:prepend-inner v-if="prependIcon">
          <v-icon :icon="prependIcon" class="mr-2"
        /></template>
        <template v-slot:append-inner v-if="appendInnerIcon">
          <v-icon
            v-if="showGreenCheckIcon"
            :id="`icon_${id}`"
            color="green"
            :icon="iconValue"
            class="mr-2"
          />
          <v-icon
            v-else-if="showExclamationIcon"
            :id="`icon_${id}`"
            color="red"
            :icon="iconValue"
            class="mr-2"
          />
        </template>
      </v-text-field>
      <span v-else class="pl-4" style="width: 100%"
        ><v-icon
          v-if="prependIcon"
          :icon="prependIcon"
          class="mt-1 mr-2"
        /><v-text-field
          readonly
          hide-details="auto"
          variant="plain"
          v-maska="maska"
          :model-value="getDisplayValue()"
        />
        <span v-if="appendWith">{{ appendWith }}</span></span
      >
      <IconTooltip
        v-if="showDurationIcon"
        :id="`icon_${id}`"
        :icon="iconValue"
        :tooltip="tooltip"
      />
    </v-col>
    <small class="noBold d-inline-flex">{{ afterText }}</small>
  </v-row>
  <v-row v-else>
    <v-col
      class="d-flex"
      :xl="labelXl"
      :lg="labelLg"
      :md="labelMd"
      :sm="labelSm"
      :cols="labelCols"
      v-if="label"
    >
      <v-label v-if="label" :id="`textarea_${id}_Grid_Label`" class="bold"
        ><span :class="isRequired ? 'required' : ''">{{ label }}</span
        ><span v-if="showColon">:</span></v-label
      >
    </v-col>
    <v-col
      class="d-flex"
      :cols="textCols"
      :xl="textXl"
      :lg="textLg"
      :md="textMd"
      :sm="textSm"
    >
      <v-textarea
        :id="`textarea_${id}_Grid`"
        :aria-describedby="`textarea_${id}_Grid`"
        :aria-label="ariaLabel"
        :variant="!isEditable ? 'outlined' : 'filled'"
        :readonly="!isEditable"
        v-model="computedValue"
        hide-details="auto"
        :required="isRequired"
        :rules="computedRules"
        :maxlength="maxlength"
        :style="textStyle"
        :placeholder="placeholder"
        :counter="counter"
        :disabled="isDisabled"
        persistentCounter
        auto-grow
        rows="1"
        @keypress="handler"
        @update:model-value="truncate"
        @blur="trim"
        @keyup="$emit('keyup')"
      ></v-textarea>
      <IconTooltip
        v-if="showDurationIcon"
        :id="`icon_${id}`"
        :icon="iconValue"
        :tooltip="tooltip"
      />
    </v-col>
  </v-row>
</template>

<script>
import { computed, ref } from "vue";
import NumberField from "@/components/common/NumberField.vue";
import { requiredRule } from "@/composables/validationRules";
import IconTooltip from "@/components/common/IconTooltip.vue";
import { useConfigStore } from "@/stores/config";
import { truncateString, encodeString } from "@/composables/util";
import { MASK } from "@/constants/Maskas";

export default {
  name: "LabelText",
  props: {
    labelXl: { default: 3 },
    labelLg: { default: 4 },
    labelMd: { default: 5 },
    labelSm: { default: 6 },
    labelCols: { default: 12 },
    textXl: { default: 4 },
    textLg: { default: 5 },
    textMd: { default: 5 },
    textSm: { default: 6 },
    textCols: { default: 12 },
    id: {},
    noExtraPadding: { type: Boolean, default: false },
    rules: { default: [] },
    label: { default: "" },
    type: { default: "text" },
    isEditable: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    modelValue: { default: "" },
    isRequired: { type: Boolean, default: false },
    decimalPlaces: { default: null },
    allowCommas: { type: Boolean, default: true },
    maxlength: { default: null },
    min: { default: null },
    showSpinner: { type: Boolean, default: false },
    appendWith: { default: null },
    prependIcon: { default: null },
    appendInnerIcon: { type: Boolean, default: false },
    textStyle: { default: "width: 50%" },
    pairCode: { default: null },
    roundingFactor: { default: null },
    showDurationIcon: { type: Boolean, default: false },
    showGreenCheckIcon: { type: Boolean, default: false },
    showExclamationIcon: { type: Boolean, default: false },
    tooltip: { default: "" },
    showComma: { type: Boolean, default: true },
    placeholder: { default: "" },
    counter: { default: "" },
    persistentCounter: { type: Boolean, deafult: false },
    showPlaceholder: { type: Boolean, default: false },
    noGrid: { type: Boolean, default: false },
    afterText: { default: null },
    showColon: { type: Boolean, default: true },
    max: { default: null },
    alignRight: { type: Boolean, default: false },
    options: { default: [] },
    refTable: { default: "" },
    password: { type: Boolean, default: false },
    noNegativeDisplayed: { type: Boolean, default: false },
    readOnlyValidation: { type: Boolean, default: false },
    showMax: { type: Boolean, default: true },
    allowNegativeInput: { type: Boolean, default: false },
    allowDecimalInput: { type: Boolean, default: false },
    conversionTypes: { default: [] },
    addTextAreaPadding: { type: Boolean, default: false },
    maska: { default: null },
    masked: { default: true },
    prependWithCurrencyIcon: { type: Boolean, default: false },
  },
  setup(props, context) {
    const configStore = useConfigStore();
    let modelValueString = ref("");
    const unmaskedValue = ref("");
    const maskaOptions = ref({
      mask: props.maska,
      eager: true,
    });
    const computedValue = computed({
      get: () => props.modelValue,
      set: (value) => {
        modelValueString.value =
          props.type == "number" ? value : encodeString(value);
        context.emit(
          "update:modelValue",
          props.masked ? modelValueString.value : unmaskedValue.value
        );
      },
    });
    const iconValue = computed({
      get: () => {
        if (props.showDurationIcon) {
          return "fa-regular fa-clock";
        } else if (props.showGreenCheckIcon) {
          return "fa-solid fa-circle-check";
        } else if (props.showExclamationIcon) {
          return "fa-solid fa-circle-exclamation";
        } else {
          return props.icon;
        }
      },
    });

    const ariaLabel = computed({
      get() {
        if (props.label == "" || !props.label) {
          return props.id + " has no label";
        } else {
          return props.label + "_" + props.id;
        }
      },
    });

    const computedRules = computed(() => {
      let validationRules = [];

      if (props.rules && props.rules.length > 0) {
        validationRules = validationRules.concat(props.rules);
      }
      if (props.isRequired) {
        validationRules.push(requiredRule);
      }
      return validationRules;
    });

    const getDisplayValue = () => {
      if (props.refTable) {
        return computedValue.value
          ? configStore.getReferenceValue(props.refTable, computedValue.value)
          : null;
      } else if (props.options) {
        const item = computedValue.value
          ? props.options.find(
              (selectItem) =>
                selectItem.value.toString() ==
                computedValue.value?.toString().trimEnd()
            )
          : null;
        if (item) {
          return item.title;
        }
      }
      return computedValue.value;
    };
    const handler = (event) => {
      if (event.which < 0x20 && event.which != 13) {
        // e.which < 0x20, then it's not a printable character like backspace etc.
        // e.which!=13 , enter key
        return; // Do nothing
      }

      if (computedValue.value?.length >= parseInt(props?.maxlength)) {
        event.preventDefault();
        return false;
      }
    };

    const truncate = () => {
      computedValue.value = ["textarea"].includes(props.type)
        ? truncateString(
            modelValueString.value?.replace(/\r\n|\r|\n/g, "\r\n"),
            parseInt(props.maxlength)
          )
        : modelValueString.value;
    };

    const trim = () => {
      const trimmedValue = ["textarea"].includes(props.type)
        ? computedValue.value?.trim()
        : computedValue.value;
      if (computedValue.value != trimmedValue) {
        computedValue.value = trimmedValue;
      }
    };

    return {
      unmaskedValue,
      iconValue,
      computedValue,
      computedRules,
      ariaLabel,
      getDisplayValue,
      handler,
      truncate,
      trim,
      maskaOptions,
      MASK,
    };
  },
  components: { IconTooltip, NumberField },
};
</script>

<style scoped lang="scss">
.verticalAlignment-center {
  align-items: center;
}
.verticalAlignment-top {
  align-items: start;
}
.noBold {
  font-weight: 400 !important;
}
</style>
