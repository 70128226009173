import { PERMISSIONS } from "@/rbac/permissions.js";
import { ACCESS_LEVELS } from "@/rbac/accessLevels.js";

const VIEW_PERMISSIONS = [
  PERMISSIONS.ROLE_BMS,
  PERMISSIONS.ROLE_BMS_APPLICATION_SECURITY_ADMINISTRATION,
  PERMISSIONS.ROLE_BMS_APRAS_BROWSER,
  PERMISSIONS.ROLE_BMS_APRAS_EDIT,
  PERMISSIONS.ROLE_BMS_BROWSER,
  PERMISSIONS.ROLE_BMS_BROWSER_INSPECTION,
  PERMISSIONS.ROLE_BMS_BROWSER_INVENTORY,
  PERMISSIONS.ROLE_BMS_LBI_USER,
  PERMISSIONS.ROLE_BMS_POWERUSER,
  PERMISSIONS.ROLE_BMS_REGISTRAR,
  PERMISSIONS.ROLE_BMS_SUPERUSER,
  PERMISSIONS.ROLE_BMS_INSPECTOR,
  PERMISSIONS.ROLE_BMS_INSPECTOR_SUPERVISOR,
];

export const ALL_CWOPA_PERMISSIONS = {
  [ACCESS_LEVELS.VIEW]: VIEW_PERMISSIONS,
};
