import { computed } from "vue";
import { useInspectionStore } from "@/stores/inspection";

export default () => {
  const inspectionStore = useInspectionStore();

  let structure = computed(() => inspectionStore.selectedInspection);

  const getData = computed({
    get() {
      const filterUserRWay = structure.value?.UserRWay?.find(
        (obj) => obj?.ON_UNDER === "1"
      );

      if (filterUserRWay === undefined) {
        return [null, undefined];
      }

      const filteredRoadWay = structure.value?.Roadway.find(
        (obj) =>
          obj?.ON_UNDER === "1" &&
          obj?.BRKEY === filterUserRWay.BRKEY &&
          obj?.FEATURE_KEY === filterUserRWay.FEATURE_KEY
      );
      const foundRoadWidth = filteredRoadWay?.ROADWIDTH ?? null;

      return [foundRoadWidth];
    },
  });

  return {
    getData,
  };
};
